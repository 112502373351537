@import "src/styles/mixins";

.carousel-wrapper {
  display: flex;
  position: relative;
  width: 100%;

  .carousel-wrapper-btn {
    position: absolute;
    left: -150px;
    top: calc(50% - 21px);
    background-color: inherit;
    border: none;
    cursor: pointer;
    z-index: 5;

    img {
      width: 42px;
      height: 42px;

      @include respond(md) {
        width: 30px;
        height: 30px;
      }
    }

    .carousel-icon-right {
      transform: rotate(180deg);
    }

    &:last-child {
      left: unset;
      right: -70px;
    }

    @include respond(md) {
      margin: 0;
      position: absolute;
      left: -53px;
      z-index: 2;

      &:last-child {
        right: -54px;
        left: auto;
      }
    }
  }

  .carousel-items-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    transform-style: preserve-3d;

    .carousel-item {
      position: absolute;
      top: 0;
      width: 100%;
      margin: auto;
      left: 350px;
      z-index: 2;
      transform: scale(0.8);
      transition: .5s, opacity .5s, z-index .5s;

      @include respond(md) {
        left: 15px;
      }

      &.active,
      &.initial {
        opacity: 1;
        position: relative;
        z-index: 4;
        transform: scale(1);
      }

      &.prev,
      &.next {
        z-index: 3;
      }

      &.prev {
        transform: scale(0.8) translateX(-42%); /* Move 'prev' item to the left */

        @include respond(md) {
          transform: scale(0.8) translateX(-38%); /* Move 'next' item to the right */
        }
      }

      &.next {
        transform: scale(0.8) translateX(27%); /* Move 'next' item to the right */

        @include respond(md) {
          transform: scale(0.8) translateX(30%); /* Move 'next' item to the right */
        }
      }

      &.prevPrev {
        opacity: 0.5;
        transform: scale(0.6) translateX(-100%); /* Move 'prev' item to the left */

        @include respond(md) {
          display: none;
        }
      }
      &.nextNext {
        opacity: 0.5;
        transform: scale(0.6) translateX(60%); /* Move 'prev' item to the left */

        @include respond(md) {
          display: none;
        }
      }
    }

    &:before {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      width: 184px;
      z-index: 10;
      content: '';
      height: 438px;
      left: -200px;

      @include respond(md) {
        left: -100px;
      }
    }
    &:after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      width: 184px;
      z-index: 10;
      content: '';
      height: 438px;
      right: -150px;
      transform: matrix(-1, 0, 0, 1, 0, 0);

      @include respond(md) {
        right: -80px;
      }
    }
  }
}