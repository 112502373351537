@import "src/styles/mixins";

.collection {
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
  padding: 120px 98px 130px 98px;

  &-bg {
    position: absolute;
    top: 0;
    right: 0;

    @include respond(md) {
     display: none;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 38px;
    color: #202020;
    max-width: 1300px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    line-height: 46px;
    margin: 0 auto 48px auto;

    @include respond(md) {
      text-align: center;
      font-size: 34px;
      line-height: 41px;
      margin: 0 auto 24px auto;
      max-width: unset;
    }
  }

  &-desc {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.06em;
    max-width: 1300px;
    color: #202020;
    margin: 0 auto 121px auto;

    @include respond(md) {
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      margin: 0 auto 40px auto;
      max-width: unset;

      br {
        display: none;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 1300px;
    margin: auto;
    justify-content: space-between;

    &-item {
      background-color: initial;
      border: none;
      cursor: pointer;
      outline: none;

      &-img {
        height: 310px;

        @include respond(md) {
          height: 134px;
        }
      }
    }

    @include respond(md) {
      max-width: 250px;
    }
  }

  @include respond(md) {
    padding: 50px 16px;
  }
}