@import "./fonts.css";
@import "./constants";
@import "./mixins";

#root {
  height: 100%;
}

body {
  font-family: $default-font !important;
  background: #131517;
  color: #fff;
  margin: 0;
  height: 100%;
}

input {
  font-family: $default-font !important;
}

button {
  font-family: $default-font !important;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}

.d-flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

html {
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}