@import "src/styles/mixins";

.partners {
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(20px);
  padding: 90px 98px 100px 98px;

  &-title {
    font-weight: 500;
    font-size: 38px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    line-height: 46px;
    margin: 0 auto 60px auto;

    @include respond(md) {
      font-size: 34px;
      line-height: 41px;
      margin: 0 20px 34px 20px;
      max-width: unset;
      text-align: center;
    }
  }

  &-arrows {
    display: flex;

    &-arrow {
      background-color: initial;
      cursor: pointer;
      padding: 0;
      border: none;
      outline: none;
      height: 36px;

      &:first-child {
        transform: rotate(180deg);
        margin-right: 15px;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 1250px;
    overflow: hidden;
    margin: auto;

    &-list {
      display: flex;
      align-items: center;
      transition: 1.5s;
    }

    &-item {
      color: #202020;
      z-index: 5;
      margin-right: 30px;
      margin-left: 30px;

      &-img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        max-width: 200px;
        max-height: 170px;
        border-radius: 8px;
        transition: 0.1s;

        &.white {
          background-color: #fff;
        }

        &:hover {
          filter: none;
        }
      }

      &.item-4 {
        .partners-content-item-img {
          max-width: 110px;
          max-height: 130px;
          margin-top: 8px;
        }
      }
      &.item-2 {
        .partners-content-item-img {
          max-width: 210px;
          max-height: 130px;
          margin-top: 8px;
        }
      }

      &:last-child {
        .partners-content-item-img {
          max-width: 170px;
          max-height: 110px;
          border-radius: 3px;
        }
      }

      @include respond(md) {
        margin-left: 26px;
        margin-right: 8px;
      }
    }

    @include respond(xlg) {
      width: 1100px;
    }

    @include respond(lg) {
      width: 1000px;
    }

    @include respond(md) {
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      overflow: scroll;
    }
  }

  @include respond(xlg) {
    padding: 120px 58px 130px 58px;
  }

  @include respond(md) {
    padding: 50px 0;
    overflow: hidden;
  }
}