@import "src/styles/mixins";

.header {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  box-shadow: rgb(0 0 0 / 0%) 0 1px 3px;
  z-index: 14;
  width: -webkit-fill-available;
  top: 0;
  transition: 0.1s;

  &-desktop {
    padding: 14px 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond(md) {
      display: none;
    }
  }

  &.offset {
    background: rgb(0, 0, 0);

    @include respond(md) {
      background: #fff;
    }
  }

  &-logo {
    width: 172px;
  }

  &-mobile {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 13px 16px;

    &-btn {
      background-color: initial;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #161616;
      padding: 0;
      outline: none;
    }

    &-sidebar {
      position: absolute;
      width: 100%;
      height: 380px;
      top: -380px;
      background: #000000;
      backdrop-filter: blur(10px);
      transition: 0.5s;
      left: 0;
      opacity: 0;
      display: flex;
      flex-direction: column;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      align-items: center;
      z-index: 1;

      &.active {
        top: 66px;
        opacity: 1;
      }
    }

    &-logo {
      height: 40px;
    }

    @include respond(md) {
      display: flex;
    }
  }

  &-links {
    display: flex;
    align-items: center;

    &-item {
      margin-right: 40px;
      cursor: pointer;
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      background-color: initial;
      border: none;
      outline: none;
      height: 15px;
      margin-top: 3px;
      padding: 0;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;


      &:last-child {
        margin-right: 0;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 20%;
        opacity: 0;
        width: 30px;
        height: 100%;
        border-bottom: 2px solid #fff;
        -webkit-box-shadow: inset 0px -1px 0px 0px #fff;
        -moz-box-shadow: inset 0px -1px 0px 0px #fff;
        box-shadow: inset 0px -1px 0px 0px #fff;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      &:hover {
        &::after {
          opacity: 1;
          bottom: -10px;
        }
      }

      @include respond(md) {
        color: #fff;
        margin-bottom: 40px;
        font-size: 20px;
        margin-right: 0;
        line-height: 24px;
      }
    }

    @include respond(md) {
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
    }
  }
}