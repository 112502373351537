@import "src/styles/mixins";

.home {
  background-color: #1E1E1E;
  padding-top: 528px;

  &-video {
    position: fixed;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include respond(md) {
      width: unset;
      left: -100px;
    }
  }

  @include respond(md) {
    padding-top: 390px;
    background-size: auto 100%;
    background-position: center;
  }

  .player-wrapper {
    position: absolute;
    z-index: 10;
    right: 96px;
    top: 106px;

    @include respond(md) {
      right: 16px;
      top: 90px;
    }

    &.hide {
      opacity: 0;
    }

    .player {
      display: none;
    }

    .player-content {
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      line-height: 17px;
    }

    .player-btn {
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
      border-radius: 12px;
      margin-right: 10px;
      width: 54px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      transition: 0.1s;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}