@import "src/styles/mixins";

.about {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, #000000 100%);
  backdrop-filter: blur(20px);
  position: relative;
  overflow: hidden;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 95px 120px 75px 96px;

    @include respond(md) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 54px 32px 40px 32px;
    }
  }

  &-left {
    h1 {
      font-weight: 500;
      font-size: 88px;
      line-height: 88px;
      margin: 0;
      text-transform: uppercase;

      @include respond(md) {
        font-size: 54px;
        line-height: 54px;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 49px;
      line-height: 59px;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      margin: 0 0 20px 0;

      @include respond(md) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    h3 {
      margin: 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.22em;
      text-transform: uppercase;
      text-align: center;
      color: rgba(255, 255, 255, 0.8);

      @include respond(md) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 38px;
      }
    }
  }

  &-right {
    &-desc {
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.16em;
      text-transform: uppercase;
      max-width: 547px;
      margin: 0 0 32px 0;

      @include respond(md) {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        max-width: 296px;
        margin: 0 0 24px 0;
      }
    }

    &-sub {
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      /* or 178% */

      letter-spacing: 0.08em;

      color: rgba(255, 255, 255, 0.6);
      max-width: 366px;

      @include respond(md) {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        max-width: 280px;
      }
    }
  }

  &-bg {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    margin-left: -10px;
    min-width: 101%;
    margin-bottom: -7px;

    @include respond(md) {
      margin: 0;
    }
  }

  &-footer-mobile {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 88px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &-ko {
      width: 90px;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-top: 8px;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  @include respond(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}