@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-SemiboldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk X-Condensed';
    src: url('FoundersGroteskXCond-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk Regular';
    src: url('FoundersGrotesk-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk Condensed';
    src: url('FoundersGroteskCond-Lt.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('FoundersGrotesk-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk X-Condensed';
    src: url('FoundersGroteskXCond-Lt.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

