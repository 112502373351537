@import "src/styles/mixins";
@import "src/styles/constants";

.specs {
  background: #000000;
  padding: 120px 98px 60px 98px;
  margin-bottom: 306px;
  position: relative;
  z-index: 1;

  &-title {
    font-weight: 500;
    font-size: 38px;
    max-width: 1300px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    line-height: 46px;
    margin: 0 auto 38px auto;

    @include respond(md) {
      text-align: center;
      font-size: 34px;
      line-height: 41px;
      margin: 0 0 24px 0;
    }
  }

  &-content {
    max-width: 1300px;
    margin: auto;
    display: flex;

    &-desc {
      max-width: 570px;
      font-weight: 400;
      font-size: 17px;
      line-height: 28px;
      letter-spacing: 0.06em;
      margin: 0;

      @include respond(md) {
        font-size: 14px;
        text-align: center;
        line-height: 22px;
      }

      &.last {
        margin-left: 108px;

        @include respond(md) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }

    @include respond(md) {
      flex-direction: column;
    }
  }

  &-btn {
    margin: 60px auto 0 auto;
    display: flex;
    align-items: center;
    background-color: #000;
    border: none;
    padding: 10px;
    font-family: $letter-font !important;
    font-size: 30px;
    color: #fff;
    outline: none;
    cursor: pointer;

    svg {
      margin-left: 15px;
    }

    @include respond(md) {
      font-size: 18px;
    }
  }

  @include respond(md) {
    padding: 50px 16px 54px 16px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 184px;
  }
}