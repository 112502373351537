@import "src/styles/mixins";

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.76);
    backdrop-filter: blur(10px);
  }

  &-content {
    width: 100%;
    z-index: 1001;
    max-height: calc(100vh - 210px);
    padding: 34px;
    position: relative;
    background: #fff;
    animation: appear 0.4s;
  }

  &-body {
    max-height: calc(100vh - 260px);
    display: flex;
    color: #202020;

    &-img {
      width: 270px;
      height: min-content;
      margin-right: 46px;

      @include respond(md) {
        margin-right: 0;
        margin-bottom: 18px;
      }
    }

    &-info {
      &-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 125% */

        letter-spacing: 0.12em;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      &-position {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.06em;
        margin-bottom: 10px;
      }

      &-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.06em;
        color: #808080;
      }

      @include respond(md) {
        text-align: center;
      }
    }

    @include respond(md) {
      flex-direction: column;
      align-items: center;
      max-height: calc(100vh - 196px);
      overflow: scroll;
    }
  }

  &-close {
    background-color: inherit;
    outline: none;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    top: 34px;
    right: 34px;
    transition: 0.1s;

    svg {
      path {
        fill: #969696;
        transition: 0.1s;
      }
    }

    &:hover {
      svg {
        path {
          fill: #000;
        }
      }
    }

    @include respond(md) {
      top: 10px;
      right: 10px;
    }
  }
}