@import "src/styles/mixins";

.team {
  background: #FFFFFF;
  position: relative;
  padding: 120px 98px;

  &-bg {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-title {
    font-weight: 500;
    font-size: 38px;
    color: #202020;
    max-width: 1300px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    line-height: 46px;
    margin: 0 auto 60px auto;

    @include respond(md) {
      font-size: 34px;
      line-height: 41px;
      margin: 0 auto 34px auto;
      max-width: unset;
      text-align: center;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 1300px;
    margin: auto;
    justify-content: space-between;

    &-item {
      color: #202020;
      z-index: 5;
      margin-bottom: 78px;

      &-index2 {
        .team-content-item-img-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .team-content-item-img {
          width: 380px;
          height: auto;

          @include respond(md) {
            width: 280px;
          }
        }
      }

      &-img {
        height: 318px;

        @include respond(md) {
          height: 230px;
        }
      }

      &-img-wrapper {
        height: 325px;
        width: 360px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        margin-bottom: 24px;

        @include respond(md) {
          width: 260px;
          height: 230px;
        }
      }

      &-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        margin-bottom: 4px;

        @include respond(md) {
          font-size: 22px;
          line-height: 30px;
          text-align: center;
        }
      }

      &-position {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.06em;
        width: 360px;
        margin-bottom: 10px;

        @include respond(md) {
          font-size: 18px;
          width: 260px;
          line-height: 30px;
          text-align: center;
        }
      }

      &-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.06em;
        color: #808080;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        width: 360px;

        @include respond(md) {
          width: 260px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
        }
      }

      &-btn {
        margin-top: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.06em;
        text-decoration-line: underline;
        color: #000000;
        background-color: initial;
        border: none;
        padding: 0;
        outline: none;
        cursor: pointer;

        @include respond(md) {
          margin: auto;
          display: block;
        }
      }

      @include respond(md) {
        margin-left: 16px;
        margin-right: 8px;
      }
    }

    @include respond(xlg) {
      width: 1100px;
    }

    @include respond(lg) {
      width: 1000px;
    }

    @include respond(md) {
      flex-wrap: nowrap;
      width: 100%;
      overflow: scroll;
    }
  }

  @include respond(xlg) {
    padding: 120px 58px 130px 58px;
  }

  @include respond(md) {
    padding: 50px 0 0 0;
    overflow: hidden;
  }
}