@import "src/styles/mixins";
@import "src/styles/constants";

.letter {
  background: #FCFCFC;
  position: relative;
  padding: 120px 96px;

  &-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 539px;
    z-index: 0;

    @include respond(xlg) {
      display: none;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 38px;
    color: #202020;
    max-width: 1300px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    line-height: 46px;
    margin: 0 auto 32px auto;
    z-index: 1;
    position: relative;

    @include respond(md) {
      font-size: 34px;
      line-height: 41px;
      margin: 0 auto 24px auto;
      max-width: unset;
      text-align: center;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 1300px;
    z-index: 1;
    position: relative;
    margin: auto;

    &-btn {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.06em;
      text-decoration-line: underline;
      background-color: initial;
      border: none;
      color: #000000;
      display: block;
      cursor: pointer;
      outline: none;
      margin: 12px auto;
    }

    &.hide {
      height: 423px;
      position: relative;
      overflow: hidden;

      &:after {
        content: " ";
        bottom: 0;
        width: 100%;
        z-index: 3;
        height: 240px;
        transform: rotate(-180deg);
        position: absolute;
        background: linear-gradient(180deg, #FCFCFC 0%, rgba(252, 252, 252, 0) 100%);
      }
    }

    &-title {
      font-family: $letter-font;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.06em;
      color: #202020;
      margin: 0 0 12px 0;

      @include respond(md) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      font-family: $letter-font;
      letter-spacing: 0.06em;
      color: #202020;
      margin: 0 0 12px 0;

      @include respond(md) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    &-footer {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 28px;
      justify-content: space-between;

      &-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        font-family: $letter-font;
        letter-spacing: 0.06em;
        color: #202020;
        display: flex;
        flex-direction: column;

        &-img {
          width: 200px;
          border-radius: 10px;
          margin-bottom: 20px;

          @include respond(md) {
            width: 150px;
          }
        }

        @include respond(md) {
          flex-direction: column;
          font-size: 16px;
          line-height: 28px;
        }
      }

      svg {
        @include respond(md) {
          width: 188px;
          height: 72px;
          margin-top: -20px;
        }
      }

      @include respond(md) {
        flex-direction: column;
        margin-top: 20px;
        align-items: flex-end;
      }
    }

    @include respond(xlg) {
      width: 1100px;
    }

    @include respond(lg) {
      width: 1000px;
    }

    @include respond(md) {
      width: auto;
      align-items: center;
      overflow: scroll;
      padding: 0 17px;
    }
  }

  @include respond(xlg) {
    padding: 120px 58px 130px 58px;
  }

  @include respond(md) {
    padding: 0 0 50px 0;
    overflow: hidden;
  }
}