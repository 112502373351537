@import "src/styles/mixins";

.collapse {
  overflow: hidden;
  position: relative;
  transition: 0.7s;
  width: 100%;
  border: 1px solid #161616;

  &-title {
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: space-between;
    transition: 0.5s;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 12px;
    letter-spacing: 0.06em;
    background-color: initial;
    border: none;
    cursor: pointer;
    outline: none;
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
  }

  &-arrow {
    background-color: inherit;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0;
  }

  &-content {
    padding: 0 12px 12px 12px;
  }

  &.open {
    .collapse-arrow {
      transform: rotate(180deg);
    }
  }
}