@import "src/styles/mixins";

.footer {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  padding-top: 66px;

  &-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    margin-bottom: 6px;

    @include respond(md) {
      margin-bottom: 4px;
      font-size: 20px;
      line-height: 34px;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    max-width: 1320px;
    margin: 5px auto 40px auto;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0.10em;
    color: #fff;

    svg {
      width: 40px;
      height: 40px;
      margin-right: 3px;
      margin-top: -7px;
    }

    @include respond(md) {
      justify-content: center;
    }
  }

  &-desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.06em;

    @include respond(md) {
      font-size: 16px;
      line-height: 30px;
    }
  }

  &-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;

    &-item {
      width: 42px;
      height: 42px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      transition: 0.1s;

      path {
        transition: 0.1s;
      }

      &:hover {
        background: #FFFFFF;

        path {
          fill: #000000;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-sub {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    font-style: italic;
    text-align: center;
    letter-spacing: 0.06em;
    margin-top: 24px;
    margin-bottom: 46px;

    @include respond(md) {
      font-size: 14px;
      line-height: 30px;
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 29px 96px;

    &-date {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.08em;
      color: rgba(255, 255, 255, 0.6);

      @include respond(md) {
        text-align: center;
        margin-top: 28px;
      }
    }

    &-connect {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &-mail {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.08em;
        text-decoration-line: underline;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
      }

      @include respond(md) {
        margin-bottom: 28px;
      }
    }

    &-powered {
      display: flex;
      align-items: center;

      &-icb {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        width: 90px;
        margin-right: 42px;
      }

      &-unicorn {
        display: flex;
        align-items: center;

        &-img {
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          width: 54px;
          margin-right: 12px;
        }

        &-info {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.08em;
          color: rgba(255, 255, 255, 0.6);

          &-link {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height, or 129% */

            letter-spacing: 0.08em;
            text-decoration-line: underline;

            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }

    @include respond(md) {
      flex-direction: column;
      align-items: center;
      padding: 30px 0;
    }
  }

  &-rights {
    background: #000000;
    display: flex;
    padding: 12px 96px;
    justify-content: space-between;
    align-items: center;

    &-left {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.08em;
      color: rgba(255, 255, 255, 0.22);

      @include respond(md) {
        margin-bottom: 8px;
      }
    }

    &-right {
      &-link {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        /* identical to box height, or 186% */

        letter-spacing: 0.08em;
        text-decoration-line: underline;

        color: rgba(255, 255, 255, 0.22);

        &:first-child {
          margin-right: 36px;
        }
      }
    }
    @include respond(md) {
      flex-direction: column;
      align-items: center;
      padding: 19px 0;
    }

  }

  @include respond(md) {
    padding-top: 50px;
  }
}