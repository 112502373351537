@import "src/styles/mixins";

.description {
  width: 100%;
  height: 100%;

  embed {
    width: 100%;
    height: 100%;
  }
}