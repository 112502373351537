@import "src/styles/mixins";

.card {
  display: flex;
  justify-content: center;
  padding: 163px 96px;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(20px);

  &-info {
    margin-right: 94px;

    &-link {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.06em;
      color: #8F8F8F;
      margin-top: 10px;
      margin-bottom: 20px;

      svg {
        margin-right: 8px;
        margin-top: -7px;
      }

      @include respond(md) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 38px;
      line-height: 46px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #FFFFFF;
      margin: 0 0 34px 0;

      span {
        font-weight: 400;
        color: #545454;
      }

      @include respond(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 34px;
        line-height: 41px;
      }
    }

    &-before-perk {
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.06em;
      white-space: pre-wrap;
      margin-bottom: 15px;

      @include respond(md) {
        font-size: 14px;
        line-height: 24px;
        margin-left: 12px;
      }
    }

    &-list {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.06em;
      padding: 0;
      color: #8F8F8F;
      max-width: 546px;

      &-item {
        margin-left: 30px;
        font-weight: 300;

        @include respond(md) {
          font-size: 14px;
          line-height: 24px;
          margin-left: 12px;
        }
      }

      @include respond(md) {
        margin: 0;
      }
    }

    @include respond(md) {
      margin-right: 0;
    }
  }

  &-preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-wrapper {
      position: relative;
      width: 608px;
      min-height: 484px;
      margin-bottom: 40px;

      @include respond(md) {
        width: 322px;
        min-height: 200px;
      }
    }

    &-loading {
      position: absolute;
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(30px);
      z-index: 2;
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }

    &-img {
      width: 608px;

      @include respond(md) {
        width: 322px;
      }
    }

    &-link {
      border: 2px solid #FFFFFF;
      padding: 22px 90px 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.06em;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      margin-bottom: 14px;
      transition: 0.1s;

      &:hover {
        background-color: #fff;
        color: #202020;
      }
    }

    &-offers {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.06em;
      color: #545454;
    }
  }

  &.black {
    flex-direction: row-reverse;
    background: rgba(0, 0, 0, 0.95);

    .card-info {
      margin-right: 0;
      margin-left: 94px;

      @include respond(md) {
        margin-left: 0;
      }
    }

    @include respond(md) {
      flex-direction: column;
    }
  }

  @include respond(md) {
    padding: 50px 19px;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner-fast {
  width: 100px;
  height: 100px;
  border-top: 5px solid #fff;
  border-right: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-fast>div{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  animation: spin 3s linear infinite;
}
