@import "src/styles/mixins";

.mint-modal {
  &-wrapper {
    position: fixed;
    background: rgba(27, 2, 2, 0.25);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 100000;
  }

  &-content {
    margin-top: 10%;
    width: 730px;
    background: #1E1E1E;
    box-shadow: 0 10px 24px rgba(129, 128, 179, 0.13);
    border-radius: 24px;
    position: relative;
    padding: 50px;
    height: min-content;
    text-align: center;
    color: #08205E;
    text-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
    font-weight: 200;
    font-size: 18px;
    line-height: 26px;

    &-video {
      width: 100%;
      border-radius: 20px;
    }

    &-icon {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;

      @include respond(md) {
        right: 14px;
        top: 14px;
      }
    }

    &-ways {
      padding-top: 20px;
      justify-content: center;
      display: flex;
      margin-bottom: 72px;
      align-items: center;
    }

    &-item {
      border: none;
      width: 159px;
      height: 170px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      margin-right: 40px;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: initial;
      transition: 0.2s;
      border-radius: 20px;

      &:hover {
        background: #3d548b;
      }

      &.active {
        background: #3d548b;
      }

      &:last-child {
        margin-right: 0;
      }

      &-icon {
        width: 88px;
        height: 88px;
      }
    }

    @include respond(md) {
      padding: 40px 10px;
      margin-top: 20%;
    }
  }
}